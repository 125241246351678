import { Box, Button, InputAdornment } from "@mui/material";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MuiTextField } from "../MuiTextField";
import MuiAutoComplete from "../MuiAutoComplete";
import { requiredText } from "../../utils/constants";
import { DateComponent } from "../DateComponent";
import { yesNo } from "../../utils/autocompleteValues";
import { useLocation } from "react-router-dom";
import { checkTemplateHasRequiredSquare } from "../../utils/functions";

const flexStyle = {
  display: "flex",
  gap: "10px",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap",
};

const WIDTH = {
  width: "49%",
};

export const TaskForm = ({ settings, submitForm, data = {}, loading }) => {
  const methods = useForm({
    defaultValues: {
      адрес: "",
      геокоординаты: "",
      площадь_подвал: "0",
      площадь_цоколь: "0",
      площадь_первый: "0",
      площадь_второй: "0",
      площадь_третий: "0",
      адм_округ: null,
      вход_расп: null,
      вход_тип: null,
      зона: null,
      метро_время: null,
      метро_имя: null,
      метро_расст: "",
      ндс: null,
      нэи: null,
      объект_тип: null,
      окна_тип: null,
      отделка: null,
      парковка: null,
      санузел: null,
      тип_здания: null,
      задание_на_оценку: "",
      дата_задания: null,
      дата_оценки: null,
      дата_осмотра: null,
      предполагаемое_использование: "",
      шаблон_отчета: null,
      цель_оценки: null,
      объект_как_в_задании: "",
      комм_расходы: null,
      опер_расходы: null,
      руководитель_проекта: "ALL_MANAGERS",
      кадастровая_стоимость: "",
      функциональное_назначение: null,
      фактическое_использование: null,
      сервитут: null,
      границы_стоимости: null,
      перепланировка: null,
      ликвидационная_стоимость: null,
      тип_площади: null,
      номер_отчета: "",
      дата_отчета: "",
      кадастровый_номер: "",
      балансовая_стоимость: "Нет данных",
      наличие_обременений: "Отсутствуют",
      муниципальный_район: "",
      этажей_в_здании: "",
      этажей_подземных: "",
      год_постройки: "",
      объект_культурного_наследия: "",
      материал_стен: "",
      окн: "Нет",
      высота_потолков: "",
      правообладатель_объекта: "",
      вид_права: "",
      номер_дата_регистрации: "",
      реквизиты_собственника: "Нет данных",
      ограничения_права: "Нет",
      площадь_сервитута: undefined,
      арендная_плата_по_сервитуту: "",
    },
  });

  const location = useLocation();

  const { handleSubmit, setValue, watch, getValues } = methods;

  const template = watch("шаблон_отчета");
  const district = watch("адм_округ");

  useEffect(() => {
    if (Object.keys(data).length) {
      for (let key in data) {
        setValue(key, data[key]);
      }
    }
  }, [data, setValue]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submitForm)}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Box sx={flexStyle}>
            <MuiTextField name={"адрес"} label={"Адрес"} sxStyle={WIDTH} />
            <MuiTextField
              name={"геокоординаты"}
              label={"Координаты"}
              sxStyle={WIDTH}
            />
          </Box>
          <Box m={2} sx={flexStyle}>
            <MuiTextField
              name={"площадь_подвал"}
              label={"Подвал"}
              type={"number"}
              onFocus={() => {
                if (getValues().площадь_подвал === "0") {
                  setValue("площадь_подвал", "");
                }
              }}
              onBlur={() => {
                if (getValues().площадь_подвал === "") {
                  setValue("площадь_подвал", "0");
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">м²</InputAdornment>
                ),
              }}
            />
            <MuiTextField
              name={"площадь_цоколь"}
              label={"Цоколь"}
              type={"number"}
              onFocus={() => {
                if (getValues().площадь_цоколь === "0") {
                  setValue("площадь_цоколь", "");
                }
              }}
              onBlur={() => {
                if (getValues().площадь_цоколь === "") {
                  setValue("площадь_цоколь", "0");
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">м²</InputAdornment>
                ),
              }}
            />
            <MuiTextField
              name={"площадь_первый"}
              label={"Первый"}
              type={"number"}
              onFocus={() => {
                if (getValues().площадь_первый === "0") {
                  setValue("площадь_первый", "");
                }
              }}
              onBlur={() => {
                if (getValues().площадь_первый === "") {
                  setValue("площадь_первый", "0");
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">м²</InputAdornment>
                ),
              }}
            />
            <MuiTextField
              name={"площадь_второй"}
              label={"Второй"}
              type={"number"}
              onFocus={() => {
                if (getValues().площадь_второй === "0") {
                  setValue("площадь_второй", "");
                }
              }}
              onBlur={() => {
                if (getValues().площадь_второй === "") {
                  setValue("площадь_второй", "0");
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">м²</InputAdornment>
                ),
              }}
            />
            <MuiTextField
              name={"площадь_третий"}
              label={"Третий"}
              type={"number"}
              onFocus={() => {
                if (getValues().площадь_третий === "0") {
                  setValue("площадь_третий", "");
                }
              }}
              onBlur={() => {
                if (getValues().площадь_третий === "") {
                  setValue("площадь_третий", "0");
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">м²</InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns:
                "repeat(auto-fit, minmax(min(100%/2, max(280px, 100%/3)), 1fr))",
              gap: 1,
            }}
          >
            <MuiTextField
              rules={{
                maxLength: {
                  value: 20,
                  message: "Должно быть не более 20 символов",
                },
              }}
              name={"задание_на_оценку"}
              label={"Задание на оценку"}
              disabled={location.pathname.includes("Edit")}
            />
            <DateComponent name={"дата_задания"} label={"Дата задания"} />
            <DateComponent name={"дата_оценки"} label={"Дата оценки"} />
            <DateComponent name={"дата_осмотра"} label={"Дата осмотра"} />
            <MuiTextField
              rules={{ required: requiredText }}
              name={"предполагаемое_использование"}
              label={"Предполагаемое использование"}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"ликвидационная_стоимость"}
              label={"Ликвидационная стоимость"}
              settings={yesNo}
              customOptions
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"шаблон_отчета"}
              label={"Шаблон отчета"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"цель_оценки"}
              label={"Цель оценки"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"адм_округ"}
              label={"Административный округ"}
              settings={settings}
            />
            <MuiAutoComplete
              name={"муниципальный_район"}
              label={"Муниципальный район"}
              settings={
                district
                  ? settings["Муниципальный район"][district]
                  : Object.values(settings["Муниципальный район"]).flat()
              }
              customOptions
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"зона"}
              label={"Кольцевая зона"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"метро_имя"}
              label={"Станция метро"}
              settings={settings}
              sxStyle={{ flex: 1 }}
            />
            <MuiTextField
              rules={{
                min: {
                  value: 0,
                  message: "Значение должно быть больше нуля",
                },
              }}
              name={"метро_расст"}
              label={"Расстояние"}
              type={"number"}
              InputProps={{
                endAdornment: <InputAdornment position="end">м</InputAdornment>,
              }}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"метро_время"}
              label={"Время от метро"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"вход_расп"}
              label={"Расположение входа"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"вход_тип"}
              label={"Тип входа"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"окна_тип"}
              label={"Наличие и размер окон"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"парковка"}
              label={"Парковка"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"объект_тип"}
              label={"Тип объекта"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"отделка"}
              label={"Состояние отделки"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"санузел"}
              label={"Наличие санузла"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"нэи"}
              label={"Наиболее эфф. использ."}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"комм_расходы"}
              label={"Комм. расходы"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"опер_расходы"}
              label={"Опер. расходы"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"ндс"}
              label={"НДС"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"тип_здания"}
              label={"Тип здания"}
              settings={settings}
            />
            <MuiAutoComplete
              freeSolo
              rules={{ required: requiredText }}
              name={"фактическое_использование"}
              label={"Фактическое использование"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"функциональное_назначение"}
              label={"Функциональное назначение"}
              settings={settings}
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"сервитут"}
              label={"Сервитут"}
              settings={yesNo}
              customOptions
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"границы_стоимости"}
              label={"Границы стоимости"}
              settings={yesNo}
              customOptions
            />
            <MuiAutoComplete
              rules={{ required: requiredText }}
              name={"перепланировка"}
              label={"Перепланировка"}
              settings={yesNo}
              customOptions
            />
            {template && template !== "П_178_4" && template !== "П_178_5" && (
              <MuiAutoComplete
                rules={{
                  required: checkTemplateHasRequiredSquare(
                    template,
                    requiredText
                  ),
                }}
                name={"тип_площади"}
                label={"Тип площади"}
                settings={settings}
              />
            )}
            {template?.indexOf("159") >= 0 && (
              <MuiTextField
                name={"площадь_сервитута"}
                label={"Площадь сервитута"}
                type={"number"}
                optional
              />
            )}
            <MuiTextField name={"номер_отчета"} label={"Номер отчета"} />
            <DateComponent name={"дата_отчета"} label={"Дата отчета"} />
            <MuiTextField
              name={"объект_как_в_задании"}
              label={"Объект как в задании"}
            />
            <MuiTextField
              name={"кадастровая_стоимость"}
              label={"Кадастровая стоимость"}
              type={"number"}
            />
            <MuiTextField
              name={"кадастровый_номер"}
              label={"Кадастровый номер"}
            />
            <MuiTextField
              name={"балансовая_стоимость"}
              label={"Балансовая стоимость"}
              optional
            />
            <MuiTextField
              name={"наличие_обременений"}
              label={"Наличие обременений"}
              optional
            />
            <MuiTextField
              name={"этажей_подземных"}
              label={"Этажей подземных"}
            />
            <MuiTextField name={"этажей_в_здании"} label={"Этажей в здании"} />
            <MuiTextField name={"год_постройки"} label={"Год постройки"} />
            <MuiTextField
              name={"объект_культурного_наследия"}
              label={"Объект культурного наследия"}
              optional
            />
            <MuiTextField name={"материал_стен"} label={"Материал стен"} />
            {template?.indexOf("178") >= 0 && (
              <MuiAutoComplete
                name={"окн"}
                label={"ОКН"}
                settings={yesNo}
                customOptions
              />
            )}
            <MuiTextField
              name={"высота_потолков"}
              label={"Высота потолков"}
              type={"number"}
            />
            <MuiTextField
              name={"правообладатель_объекта"}
              label={"Правообладатель объекта"}
            />
            <MuiAutoComplete
              freeSolo
              name={"вид_права"}
              label={"Вид права"}
              settings={settings}
            />
            <MuiTextField
              name={"номер_дата_регистрации"}
              label={"Номер дата регистрации"}
              optional
            />
            <MuiTextField
              name={"реквизиты_собственника"}
              label={"Реквизиты собственника"}
              optional
            />
            <MuiTextField
              name={"ограничения_права"}
              label={"Ограничения права"}
              optional
            />
            <MuiTextField
              name={"арендная_плата_по_сервитуту"}
              label={"Арендная плата по сервитуту"}
              optional
            />
          </Box>
          <Button
            type="submit"
            sx={{ maxWidth: "240px" }}
            variant="contained"
            color="success"
            disabled={loading}
          >
            Сохранить задание
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};
